























































import {Component, Prop, Vue} from "vue-property-decorator";
import {Login, login} from "@/common/login/login";
import {DeepLoop} from "@/common/deepLoop/deepLoop";
import getMenuList from "@/common/asideMenu";
@Component
export default class HelloWorld extends Vue {
  private param: login = {
    username: "",
    password: "",
    type: 1,
  };

  private loginF: Login;

  private deepLoopUtil: any;

  constructor() {
    super();

    this.loginF = new Login();

    this.deepLoopUtil = new DeepLoop();

  }

  private async submitForm() {
    this.submitErrMsg = "";
    // const ShopId: any = location.href.split("=")[1].split("#")[0];
    let ShopId: String = ''
    const searchParams = location.search

    if (searchParams) {
      var params = searchParams.substring(1).split('&').reduce(function (obj, param) {
        var pair = param.split('=');
        obj[pair[0]] = decodeURIComponent(pair[1]);
        return obj;
      }, {});
      ShopId = params.shop_id
    } else {
      //开发环境
      if (process.env.NODE_ENV === 'development') {
        ShopId = '2ey3'
      }else{
        this.$message.error('链接参数缺失');
      }
    }
    console.log('ShopId', ShopId)
    if (ShopId == null) {
      this.submitErrMsg = "请输入店铺ID";
      return;
    }
    localStorage.setItem("ShopId", ShopId);
    this.$store.commit('setShopId',ShopId)
    if (!this.param.username) {
      this.submitErrMsg = "请输入用户名";
      return;
    } else if (!this.param.password) {
      this.submitErrMsg = "请输入密码";
      return;
    }
    const param = this.deepLoopUtil.deepCopy(this.param);
    this.loginF.submitForm(param, async (res: any) => {
          const menus = await getMenuList()
          console.log('菜单列表',menus)
          localStorage.setItem("menus", JSON.stringify(menus));
          this.$router.push(menus[0].children[0].url);
        },
        (msg: string) => {
          this.submitErrMsg = msg;
        }
    );
  }

  private submitErrMsg: string = "";

  private created() {
    localStorage.clear();
    // console.log(location.href.split("=")[1].split("#")[0]);
  }
}
